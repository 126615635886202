import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import Project from './Project';
import './i18n';
import { Providers } from './providers';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="Loading Translations...">
      <Providers>
        <Project />
      </Providers>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
