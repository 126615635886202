import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { spacerize, underscorize } from '../../../utils';
import StepIndicator from '../../StepIndicator/StepIndicator';
import API from '../../../API';
import StepContent from '../../StepContent/StepContent';
import { useUser } from '../../../providers/UserProvider';
import { SecondaryButton } from '../../SecondaryButton';

export default function EditOrigin(props) {
  const { t } = useTranslation('translation');
  const { originId } = useParams();
  const [loading, setLoading] = useState(true);
  const [originData, setOriginData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepDetails, setCurrentStepDetails] = useState(null);
  const [canClick, setCanClick] = useState(false);
  const [connectionTestStatus, setConnectionTestStatus] = useState(null);
  const [dispatchSave, setDispatchSave] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { csrf_token } = useUser();

  useEffect(() => {
    const newQueryParams = queryParams;
    if (!newQueryParams.has('step')) {
      newQueryParams.append('step', 0);
    }
    history.push({ search: newQueryParams.toString() });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchOriginData() {
      setOriginData({});
      setCurrentStep(0);
      setLoading(true);
      const result = await API.get(`/service_credentials/${originId}`, {
        withCredentials: true,
      });
      setOriginData(result.data.service_credential);
      const loadedStep = parseInt(queryParams.get('step'));
      if (loadedStep > 0) {
        setCurrentStep(parseInt(loadedStep));
      }
      setLoading(false);
    }

    fetchOriginData();
    // eslint-disable-next-line
  }, [originId]);

  useEffect(() => {
    setCanClick(false);
    if (originData.service_creation_steps) {
      if (originData.service_creation_steps[currentStep]) {
        const { needed_steps } = originData.service_creation_steps[currentStep];
        if (
          !needed_steps ||
          needed_steps.every(
            stepIndex => originData.service_creation_steps[stepIndex].finished
          )
        ) {
          setCurrentStepDetails(originData.service_creation_steps[currentStep]);
        } else {
          updateCurrentStep(currentStep - 1);
        }
      } else {
        updateCurrentStep(0);
      }
    }
    // eslint-disable-next-line
  }, [currentStep, originData.service_creation_steps]);

  function updateCurrentStep(step) {
    const newQueryParams = queryParams;
    newQueryParams.delete('step');
    newQueryParams.append('step', step);
    history.push({ search: newQueryParams.toString() });
    setCurrentStep(step);
  }

  async function finishCurrentStep() {
    if (connectionTestStatus === false) {
      setConnectionTestStatus(null);
      updateCurrentStep(0);
      return;
    }

    setCanClick(false);

    const result = await API.post(
      `/service_credentials/${originData.id}/finish_step`,
      { step_number: currentStep },
      {
        headers: { 'X-CSRF-Token': csrf_token },
        withCredentials: true,
      }
    ).catch();

    const {
      data: { service_credential },
    } = result;
    service_credential.service_creation_steps[currentStep].finished = true;
    setOriginData(service_credential);
    setCanClick(true);
    if (isLastStep()) {
      history.push('/');
    } else if (
      !service_credential.service_creation_steps[currentStep + 1]?.blocked
    ) {
      updateCurrentStep(currentStep + 1);
    }
  }

  function isLastStep() {
    return currentStep === originData.service_creation_steps.length - 1;
  }

  function save() {
    setDispatchSave(dispatchSave + 1);
  }

  function getCorrectBtnLabel() {
    function finishOrContinue() {
      if (isLastStep()) {
        return t('stepContent.finish');
      } else {
        return t('stepContent.saveAndContinue');
      }
    }

    if (currentStepDetails?.type === 'connTest') {
      if (connectionTestStatus === true) {
        return finishOrContinue();
      } else if (connectionTestStatus === null) {
        return t('stepContent.loadingConnTest');
      } else {
        return t('stepContent.errorConnTest');
      }
    }
    return finishOrContinue();
  }

  function goToDfm() {
    window.open(
      `${process.env.REACT_APP_DFM_URL}?category=ServiceCredential&service=${originId}`,
      '_blank'
    );
  }

  return (
    <div className="editOrigin">
      {loading ? (
        <div className="fullsize flex">
          <div className="label">{t('editOrigin.loading')}</div>
          <Loader />
        </div>
      ) : (
        [
          <div className="titleBar" key="titleBar">
            <img
              className="originIcon"
              src={`/assets/logos/origins/${underscorize(
                originData.type
                  .replace('ServiceCredential::', '')
                  .replace('::', '')
              )}.png`}
              alt={`logo_${props.origin}`}
              width="auto"
              height="auto"
            />
            <div className="label is-hidden-touch">
              {t('editOrigin.editing', {
                origin: spacerize(
                  originData.type
                    .replace('ServiceCredential::', '')
                    .replace(/::/gm, '')
                ),
              })}
              <div className="originName">"{originData.name}"</div>
            </div>
            <SecondaryButton
              label={t('editOrigin.goToLogs')}
              onClick={goToDfm}
              flexAlign="right"
            />
          </div>,
          <div className="originName is-hidden-desktop" key="originNameMobile">
            {originData.name}
          </div>,
          <div className="subTitleBar" key="subTitleBar">
            {originData?.service_creation_steps[currentStep]?.name}
          </div>,
          <div className="stepContent" key="stepContent">
            <StepContent
              finishStep={finishCurrentStep}
              step={currentStepDetails}
              setCanClick={setCanClick}
              setConnectionTestStatus={setConnectionTestStatus}
              dispatchSave={dispatchSave}
              originData={originData}
            />
          </div>,
          <div className="bottomBar" key="bottomBar">
            <button
              className="btn validation-btn"
              onClick={save}
              disabled={!canClick}
              data-testid={'validation-btn'}
            >
              {getCorrectBtnLabel()}
            </button>
            <StepIndicator
              steps={originData.service_creation_steps}
              currentStep={currentStep}
              stepChange={updateCurrentStep}
            />
          </div>,
        ]
      )}
    </div>
  );
}
