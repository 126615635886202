export const origins = [
  { label: 'agora_uk' },
  { label: 'alltricks' },
  { label: 'amazon' },
  { label: 'amazon_retail' },
  { label: 'animalis' },
  { label: 'ankorstore' },
  { label: 'atlas_for_men' },
  { label: 'auchan_mkp' },
  { label: 'back_market' },
  { label: 'bebe_au_naturel' },
  { label: 'big_commerce' },
  { label: 'bol' },
  { label: 'bhv' },
  { label: 'bleu_bonheur' },
  { label: 'boulanger' },
  { label: 'brico_prive' },
  { label: 'brico_prive_mirakl' },
  { label: 'bricomarchefr' },
  { label: 'bulevip' },
  { label: 'but' },
  { label: 'cadeaux_dot_com' },
  { label: 'carethy' },
  { label: 'carrefourfr' },
  { label: 'carrefoures' },
  { label: 'cdiscount' },
  { label: 'cleor' },
  { label: 'colizey' },
  { label: 'conforama' },
  { label: 'creavea' },
  { label: 'darty' },
  { label: 'decathlon' },
  { label: 'delamaison' },
  { label: 'distriartisan' },
  { label: 'ebay' },
  { label: 'emag' },
  { label: 'empik' },
  { label: 'eprice' },
  { label: 'etsy' },
  { label: 'fnac' },
  { label: 'france_boissons' },
  { label: 'galeries_lafayette' },
  { label: 'gosport_mirakl' },
  { label: 'greenweezfr' },
  { label: 'grenieralpin' },
  { label: 'groupon' },
  { label: 'home24' },
  { label: 'ibs' },
  { label: 'inno' },
  { label: 'intermarche' },
  { label: 'kiabi' },
  { label: 'la_poste' },
  { label: 'la_redoute' },
  { label: 'ldlc' },
  { label: 'leclerc' },
  { label: 'leroymerlin' },
  { label: 'macway' },
  { label: 'manomano' },
  { label: 'magento_v1', connectorName: 'ServiceCredential::Magento::V1' },
  { label: 'magento_v2', connectorName: 'ServiceCredential::Magento::V2' },
  { label: 'maison_du_monde' },
  { label: 'maty' },
  { label: 'media_markt_saturn' },
  { label: 'metro' },
  { label: 'vente_unique' },
  { label: 'nature_et_decouvertes' },
  { label: 'nocibe' },
  { label: 'outiz' },
  { label: 'oxatis' },
  { label: 'pc_componentes' },
  { label: 'piazz' },
  { label: 'pink_connect' },
  { label: 'pixmania' },
  { label: 'pour_de_bon' },
  { label: 'prestashop' },
  { label: 'priceminister' },
  { label: 'privalia' },
  { label: 'private_sport_shop' },
  { label: 'real_de' },
  { label: 'refurbed_de' },
  { label: 'retif' },
  { label: 'rue_du_commerce' },
  { label: 'seller_mania' },
  { label: 'sellsy' },
  { label: 'shopify' },
  { label: 'shopping_feed' },
  { label: 'showroom_prive_mp' },
  { label: 'showroom_prive' },
  { label: 'spartoo' },
  { label: 'sprinter' },
  { label: 'stop_covid19' },
  { label: 'tradeinnes' },
  { label: 'trendy_foods_be' },
  { label: 'truffaut' },
  { label: 'twil' },
  { label: 'ubaldi' },
  { label: 'veepee' },
  { label: 'vidaxl' },
  { label: 'wayfair' },
  { label: 'wizishop' },
  { label: 'worten' },
  { label: 'woocommerce' },
  { label: 'zalando' },
];
