import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SCActionButton(props) {
  const { t } = useTranslation();
  const { action_button } = props;

  const realizeAction = () => {
    window.open(action_button.url, '_blank');
  };

  return (
    <div className="SC centered">
      <div className="mb-6">{t('actionButton')}</div>
      <button className="actionButton" onClick={realizeAction}>
        <i className="icon-open-in-new mr-2" />
        {action_button['name']}
      </button>
    </div>
  );
}
