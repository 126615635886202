import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from '../../../API';
import { camelize, capitalize } from '../../../utils';
import Loader from '../../Loader/Loader';
import DetailedOriginCard from './DetailedOriginCard';
import { origins } from '../../../config/origins.config';

export default function OriginTypeListing(props) {
  const { t } = useTranslation('translation');
  const { originType } = useParams();
  const [originInfos, setOriginInfos] = useState([]);
  const [loadedOriginInfos, setLoadedOriginInfos] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    async function fetchData() {
      setOriginInfos([]);
      setLoadedOriginInfos(false);
      const result = await API.get('/service_credentials', {
        withCredentials: true,
      });
      /* istanbul ignore else */
      if (mountedRef.current) {
        const _origin = origins.find(o => o.label === originType);
        setOriginInfos(
          result.data?.service_credentials.filter(
            sc =>
              sc.type.replace('ServiceCredential::', '') ===
                capitalize(camelize(originType)) ||
              sc.type === _origin?.connectorName
          )
        );
        setLoadedOriginInfos(true);
      }
    }

    fetchData();
    return () => {
      mountedRef.current = false;
    };
  }, [originType]);

  function getActivesOrigins() {
    return originInfos.filter(oi => oi.order_fetcher_enabled).length;
  }

  function shouldBeFiller() {
    return originInfos.length > 1 && Math.abs(originInfos.length % 2) === 1;
  }

  return (
    <div className="origin-type-listing">
      <div className="mainLabel" key="label">
        <div className="headerInfos">
          <div className="colHeader">
            <img
              src={`/assets/logos/origins/${originType}.png`}
              alt={`logo_${originType}`}
              width="auto"
              height="auto"
              className="originIcon"
            />
            <Link to={`/add-origin/${originType}`} className="addOrigin">
              +
            </Link>
          </div>

          {t('main.sourceListing', {
            originType: capitalize(originType).replace(/_/g, ' '),
          })}
        </div>
        {loadedOriginInfos ? (
          <div className="countAndConfLabel">
            <span className="originLabel" data-testid="labelinfos">
              {originInfos.length ? ' ' + originInfos.length + '  ' : 0}
            </span>
            <span> {t('main.configuredOrigins')}</span>
            <span className="originLabel">
              {originInfos.length ? ' ' + getActivesOrigins() + '  ' : 0}
            </span>
            <span> {t('main.activatedOrigins')}</span>
          </div>
        ) : null}
      </div>
      <div className="content">
        {loadedOriginInfos ? (
          originInfos.length ? (
            <div className="listing">
              {originInfos.map((origin, i) => (
                <DetailedOriginCard
                  key={i + origin.name}
                  origin={origin}
                  originType={originType}
                  isActive={origin.order_fetcher_enabled}
                />
              ))}
              {shouldBeFiller() ? (
                <div className="cardFiller" data-testid="cardFiller" />
              ) : null}
            </div>
          ) : (
            t('main.noSourceFound', {
              originType: capitalize(originType).replace(/_/g, ' '),
            })
          )
        ) : (
          <div className="fullLoader">
            <Loader data-testid="listingLoading" />
          </div>
        )}
      </div>
      <Link to={`/add-origin/${originType}`} className="btn" id="createBtn">
        +{' '}
        {t('main.createOrigin', {
          originType: capitalize(originType).replace(/_/g, ' '),
        })}
      </Link>
    </div>
  );
}
