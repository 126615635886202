import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { origins } from '../../../config/origins.config';
import OriginCard from './OriginCard';
import API from '../../../API';
import { camelize, capitalize, groupBy } from '../../../utils';
import { FilterBar } from '../../FilterBar/FilterBar';

export default function OriginListing(props) {
  const { t } = useTranslation('translation');

  const [originInfos, setOriginInfos] = useState([]);
  const [loadedOriginInfos, setLoadedOriginInfos] = useState(false);
  const [currentSearch, setCurrentSearch] = useState({
    search: '',
    activeOnly: false,
  });
  const [originList, setOriginList] = useState(origins);

  const mountedRef = useRef(true);

  const isOriginActivated = useCallback(
    _origin => {
      const origin = `ServiceCredential::${capitalize(camelize(_origin))}`;
      const list = groupBy(originInfos, 'type');

      return (
        list[origin]?.some(
          ({ order_fetcher_enabled }) => order_fetcher_enabled
        ) || false
      );
    },
    [originInfos]
  );

  useEffect(() => {
    async function fetchData() {
      setOriginInfos([]);
      setLoadedOriginInfos(false);
      const result = await API.get('/service_credentials', {
        withCredentials: true,
      });
      if (mountedRef.current) {
        setOriginInfos(result.data?.service_credentials);
        setLoadedOriginInfos(true);
      }
    }

    fetchData();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const { search, activeOnly } = currentSearch;
    const list = [...origins]
      .sort((a, b) => {
        return (
          (a.disable && !b.disable && 1) ||
          (!a.disable && b.disable && -1) ||
          a.label.localeCompare(b.label)
        );
      })
      .filter(({ label }) =>
        search
          ? label
              .toLowerCase()
              .replace(/_/g, ' ')
              .includes(search.toLowerCase())
          : label
      )
      .filter(({ label }) => (activeOnly ? isOriginActivated(label) : label));

    setOriginList(list);
  }, [currentSearch, originInfos, isOriginActivated]);

  function detailsFor(origin) {
    if (loadedOriginInfos) {
      return originInfos
        .filter(
          oinfos =>
            oinfos.type.replace('ServiceCredential::', '') ===
            capitalize(camelize(origin))
        )
        .reduce(
          (result, oinfos) => {
            result.configuredOrigins++;
            if (oinfos.order_fetcher_enabled) {
              result.activeOrigins++;
            }
            return result;
          },
          { configuredOrigins: 0, activeOrigins: 0 }
        );
    }
  }

  const updateFilter = useCallback(({ search, activeOnly }) => {
    setCurrentSearch({ search: search, activeOnly: activeOnly });
  }, []);

  return (
    <div className="full-content-centered" key="originCards">
      <div className="mainLabel" key="label">
        {t('main.configureSources')}
      </div>
      <div className="utils-container">
        <FilterBar onChange={updateFilter} />
      </div>
      {originList?.length ? (
        <div className={'origin-list'}>
          {originList.map(({ label: origin, disable }) => (
            <OriginCard
              origin={origin}
              key={origin}
              details={detailsFor(origin)}
              disable={disable}
            />
          ))}
        </div>
      ) : (
        <span>{t('main.noResultsWithFilter')}</span>
      )}
    </div>
  );
}
