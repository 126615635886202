import React, { useEffect, useRef, useState } from 'react';
import ValidCheck from '../Loader/ValidCheck';
import InvalidCheck from '../Loader/InvalidCheck';
import Loader from '../Loader/Loader';
import { useTranslation } from 'react-i18next';
import API from '../../API';
import moment from 'moment';
import { useUser } from '../../providers/UserProvider';

export default function SCConnTest(props) {
  const { t } = useTranslation();
  const [testState, setTestState] = useState('pending');
  const { setCanClick, setConnectionTestStatus } = props;
  const didMountRef = useRef(false);
  const { csrf_token } = useUser();

  const { order_fetcher_id, id } = props.originData;
  async function activatesOrderFetcher() {
    return await API.put(
      `/order_fetchers/${order_fetcher_id}`,
      {
        order_fetcher: {
          fetcher_enabled: true,
          last_updated_at_in_api_fmt: moment()
            .subtract(30, 'days')
            .startOf('day')
            .format(),
        },
      },
      {
        headers: { 'X-CSRF-Token': csrf_token },
        withCredentials: true,
      }
    );
  }

  async function connectionStatusCheckingProcess() {
    const serverCredentialsValidity = await API.get(
      `/service_credentials/${id}/check_credentials`,
      { withCredentials: true }
    );

    if (serverCredentialsValidity.data.passedCheck) {
      await activatesOrderFetcher();
      setTestState('Valid');
      setCanClick(true);
      setConnectionTestStatus(true);
    } else {
      setTestState('Error');
      setCanClick(true);
      setConnectionTestStatus(false);
    }
  }

  useEffect(() => {
    connectionStatusCheckingProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function save() {
    props.finishStep();
  }

  useEffect(() => {
    if (didMountRef.current) {
      save();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatchSave]);

  return (
    <span className="SCConnTest">
      <div className="top-bar">
        {testState === 'Valid' &&
        props.service_credential_data?.installed_from ? (
          <span
            className={`installed-from-badge installed-from-badge-${props.service_credential_data.installed_from}`}
          >
            <i className="icon-check mr-2" />
            Installed from {props.service_credential_data.installed_from}
          </span>
        ) : null}
      </div>
      <div className="SC centered">
        {testState === 'Valid' ? (
          <div>
            <ValidCheck />
            {t('stepContent.sConnTest')}
          </div>
        ) : testState === 'Error' ? (
          <div>
            <InvalidCheck data-testid="invalid" />
            {t('stepContent.sConnTestErr')}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </span>
  );
}
