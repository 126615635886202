import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function SCImage(props) {
  const scrollRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [loadedImage, setLoadedImage] = useState(false);
  const didMountRef = useRef(false);

  function save() {
    props.finishStep();
  }

  useEffect(() => {
    if (didMountRef.current) {
      save();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatchSave]);

  const intersectionOptions = useMemo(
    () => ({
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }),
    []
  );

  useEffect(() => {
    if (loadedImage) {
      const currentScrollRef = scrollRef.current;
      const observer = new IntersectionObserver(entries => {
        const [entry] = entries;
        /* istanbul ignore else */
        if (entry.isIntersecting) {
          setIsScrolled(true);
        }
      }, intersectionOptions);
      observer.observe(currentScrollRef);
      return () => {
        observer.unobserve(currentScrollRef);
      };
    }
  }, [intersectionOptions, loadedImage]);

  const { setCanClick } = props;

  useEffect(() => {
    setCanClick(isScrolled);
  }, [isScrolled, setCanClick]);

  return (
    <div className="SCImage SC">
      <div className="image-holder">
        <img
          src={props.source}
          alt="tutorial"
          onLoad={() => setLoadedImage(true)}
        />
        <span ref={scrollRef} id="scImageBottom"></span>
      </div>
    </div>
  );
}
