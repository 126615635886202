import React from 'react';
import ReactTooltip from 'react-tooltip';

function StepDot(props) {
  let className = 'stepDot';
  if (props.finished) {
    className += ' finished';
  }
  if (props.blocked) {
    className += ' blocked';
  } else {
    className += ' navigable';
  }
  if (props.isCurrentStep) {
    className += ' current';
  }

  return (
    <div
      className={className}
      data-tip={(props.finished ? '✔️' : '❌') + ' ' + props.name}
      onClick={() => {
        if (!props.blocked) {
          props.stepChange(props.index);
        }
      }}
      data-testid="step-dot"
    >
      <ReactTooltip place="top" effect="solid" />
    </div>
  );
}

export default function StepIndicator(props) {
  return (
    <div className="stepIndicator">
      {props.steps?.map((step, i) => {
        step.blocked =
          step.needed_steps &&
          !step.needed_steps.every(
            stepToCheck => props.steps[stepToCheck].finished
          );
        return (
          <StepDot
            {...step}
            index={i}
            key={'step' + i}
            isCurrentStep={i === props.currentStep}
            stepChange={props.stepChange}
          />
        );
      })}
    </div>
  );
}
