import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { camelize, capitalize } from '../../../utils';
import API from '../../../API';
import { useUser } from '../../../providers/UserProvider';
import { origins } from '../../../config/origins.config';

export default function CreateOrigin(props) {
  const history = useHistory();
  const [creating, setCreating] = useState(false);
  const [originName, setOriginName] = useState('');
  const { t } = useTranslation('translation');
  const { originType } = useParams();
  const { csrf_token } = useUser();

  function changeName(evt) {
    setOriginName(evt.target.value);
  }

  function handleKeyDown(evt) {
    if (evt.key === 'Enter' && originName.length) {
      createOrigin();
    } else if (evt.key == 'Escape') {
      setOriginName('');
    }
  }

  function createOrigin() {
    async function fetchData() {
      setCreating(true);

      const _origin = origins.find(o => o.label === originType);

      const result = await API.post(
        '/service_credentials',
        {
          name: originName,
          type:
            _origin.connectorName ||
            `ServiceCredential::${capitalize(camelize(originType))}`,
        },
        {
          headers: { 'X-CSRF-Token': csrf_token },
          withCredentials: true,
        }
      );
      history.push(`/edit-origin/${result.data.service_credential.id}`);
    }

    fetchData();
  }

  if (creating) {
    return (
      <div className="createOrigin">
        <div className="label">
          {t('createOrigin.creating', {
            origin: capitalize(originType).replace(/_/g, ' '),
          })}
        </div>
        <img
          className="originIcon"
          src={`/assets/logos/origins/${originType}.png`}
          alt={`logo_${originType}`}
          width="250"
          height="140"
        />
        <Loader height={100} />
      </div>
    );
  } else {
    return (
      <div className="createOrigin beforeCreate">
        <div className="titleBar" key="titleBar">
          <img
            className="originIcon"
            src={`/assets/logos/origins/${originType.toLowerCase()}.png`}
            alt={`logo_${originType}`}
            width="auto"
            height="auto"
          />
          <div className="label is-hidden-touch">
            {t('createOrigin.beforeCreating', {
              origin: capitalize(originType).replace(/_/g, ' '),
            })}
          </div>
        </div>
        <div className="subTitleBar" key="subTitleBar">
          {t('createOrigin.originNaming')}
          <div className="inputName">
            <input
              className="input"
              value={originName}
              aria-label="originName"
              onChange={changeName}
              onKeyDown={handleKeyDown}
            />
          </div>
          <button
            className="btn createOriginBtn"
            disabled={!originName.length}
            onClick={createOrigin}
          >
            {t('createOrigin.create')}
          </button>
        </div>
      </div>
    );
  }
}
