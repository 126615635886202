import React from 'react';

export default function Loader(props) {
  const holderStyle = { height: (props.height || 0) + 15, display: 'flex' };
  const loaderStyle = {};
  if (props.diameter) {
    loaderStyle.height = props.diameter;
    loaderStyle.width = props.diameter;
  }
  return (
    <div style={holderStyle}>
      <div
        className="loader"
        style={loaderStyle}
        role="alert"
        aria-busy="true"
        data-testid={props['data-testid']}
      ></div>
    </div>
  );
}
