import { Input } from './Input';
import React from 'react';
import { SboSelect } from './Select';
import { SboCheckbox } from './Checkbox';

export const Fields = ({
  field,
  type,
  index,
  value,
  onChange,
  required,
  className,
}) => {
  if (type === 'select' && field.options) {
    return (
      <>
        <SboSelect
          label={field.name}
          onChange={onChange}
          value={value}
          options={field.options}
          required={required}
          className={className}
          id={field.key}
        />
      </>
    );
  } else if (type === 'checkbox') {
    return (
      <SboCheckbox
        id={field.key}
        className={className}
        onChange={onChange}
        label={field.name}
        value={value}
      />
    );
  } else {
    return (
      <>
        <Input
          label={field.name}
          id={field.key}
          value={value}
          onChange={onChange}
          state={field.state}
          error={field.error}
          index={index}
          className={className}
          required={required}
        />
      </>
    );
  }
};
