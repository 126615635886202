export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const groupBy = function (list, key) {
  return list.reduce(function (previous, currentValue) {
    (previous[currentValue[key]] = previous[currentValue[key]] || []).push(
      currentValue
    );
    return previous;
  }, {});
};

export function camelize(str) {
  return str.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2, offset) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
}

export function underscorize(str) {
  return str.replace(/[A-Z]/g, function (char, index) {
    return (index !== 0 ? '_' : '') + char.toLowerCase();
  });
}

export function spacerize(str) {
  return str.replace(/[A-Z]/g, function (char, index) {
    return index !== 0 ? ' ' + char.toLowerCase() : char;
  });
}
