import i18next from 'i18next';

function IsUrlCorrect(value, params) {
  const { urlStartingStrings } = params;
  const urlregex = new RegExp(
    `(${urlStartingStrings.join('|')})([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?`
  );

  const isRegexOk = urlregex.test(value);
  if (isRegexOk) {
    return { state: true };
  }
  return {
    state: false,
    err: i18next.t('fieldsErrors.urlIncorrect', {
      url_valid_starts: urlStartingStrings,
    }),
  };
}

function marketPlaceAmazonId(value, params) {
  const { nb_chars } = params;
  const marketPlaceRegex = new RegExp('^([A-Z\\d]+?)$');

  if (value.length === nb_chars) {
    const isRegexOk = marketPlaceRegex.test(value);
    console.debug('regex check : ', isRegexOk);
    if (isRegexOk) return { state: true };
    else {
      return {
        state: false,
        err: i18next.t('fieldsErrors.amazonMarketPlaceIncorrectCharacters'),
      };
    }
  }

  return {
    state: false,
    err: i18next.t('fieldsErrors.amazonMarketPlaceIncorrectLength'),
  };
}

function regexCheck(value, params) {
  const { stringToRegex } = params;
  const regexToTest = new RegExp(decodeURIComponent(stringToRegex));
  const isRegexOk = regexToTest.test(value);

  if (isRegexOk) return { state: true };

  return {
    state: false,
    err: i18next.t('fieldsErrors.regexincorrect'),
  };
}

function apiKey(value, params) {
  const { nb_chars } = params;

  if (value.length !== nb_chars)
    return {
      state: false,
      err: i18next.t('fieldsErrors.stringLengthMin', { value: nb_chars }),
    };
  return { state: true };
}

const validationRules = {
  apiKey: apiKey,
  regexCheck: regexCheck,
  url: IsUrlCorrect,
  amazonMarketId: marketPlaceAmazonId,
};

export default validationRules;
