import React from 'react';

export default function ValidCheck() {
  return (
    <div data-testid="validgreencheck" className="valid-check">
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div>
  );
}
