import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Navbar(props) {
  const [state, setState] = useState({
    isNavbarOpen: false,
  });

  const { t } = useTranslation();

  const toggleNav = function () {
    setState(state => ({
      isNavbarOpen: !state.isNavbarOpen,
    }));
  };

  function pageName() {
    return 'Shippingbo Connect';
  }

  return (
    <>
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        data-testid="navbar"
      >
        <div className="navbar-brand">
          <span
            role="button"
            className={
              state.isNavbarOpen
                ? 'navbar-burger is-active is-hidden-tablet'
                : 'navbar-burger is-hidden-tablet'
            }
            onClick={toggleNav}
            aria-label="nav-toggle"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
          <a className="navbar-item is-hidden-mobile" href="/">
            <img
              src="/assets/logos/sbconnect.png"
              alt="logo"
              width="50"
              height="auto"
            />
          </a>
          <a className="navbar-item is-hidden-tablet mobile-logo" href="/">
            <img
              src="/assets/logos/sbconnect.png"
              alt="logo"
              width="50"
              height="auto"
            />
          </a>
          <div className="navbar-item vertical-navbar-separator is-hidden-mobile"></div>
          <div
            className="navbar-item is-hidden-mobile"
            aria-label="current-page-title"
          >
            {pageName()}
          </div>
          <div className="navbar-item navbar-sbo align-end is-hidden-mobile">
            <a
              href={process.env.REACT_APP_OMS_SERVER}
              role="button"
              className="go-sbo-button"
            >
              <i className="icon-arrow-back-left" />
              {t('common.goBackToSbo')}
            </a>
          </div>
        </div>

        <div
          className={
            state.isNavbarOpen
              ? 'navbar-menu is-active is-hidden-tablet'
              : 'navbar-menu is-hidden-tablet'
          }
          data-testid="navbar-menu"
        >
          <div className="navbar-end">
            <a
              className="navbar-item"
              href={process.env.REACT_APP_OMS_SERVER}
              role="button"
              className="go-sbo-button"
            >
              <i className="icon-arrow-back-left" />
              {t('common.goBackToSbo')}
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
