import React from 'react';

export const SearchBar = ({ onSearch, label, className }) => {
  return (
    <>
      <form className={`search-form ${className}`}>
        <i className={'icon-magnifier icon'} />
        <input
          type="search"
          placeholder={label}
          className="search-input"
          data-testid={'search-bar'}
          onChange={evt => onSearch(evt.target.value)}
        />
      </form>
    </>
  );
};
