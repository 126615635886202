import React from 'react';

export const SecondaryButton = ({
  onClick,
  label,
  icon,
  disabled,
  flexAlign,
  testid,
}) => {
  return (
    <>
      <button
        className={'SecondaryButton' + (flexAlign ? ` align${flexAlign}` : '')}
        disabled={disabled}
        onClick={onClick}
        data-testid={testid}
        role="button"
      >
        {icon ? <i className={icon} /> : null}
        {label ? label : null}
      </button>
    </>
  );
};
