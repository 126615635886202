import React from 'react';
import ContentPane from './ContentPane';
import Navbar from './Navbar';
import { BrowserRouter as Router } from 'react-router-dom';

/* istanbul ignore if */
if (process.env.REACT_APP_PENDO_KEY) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src =
        'https://content.pendo.shippingbo.com/agent/static/' +
        apiKey +
        '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(process.env.REACT_APP_PENDO_KEY);
}

/* Communication Layer of the application */
export default function Project() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <div className="full_content">
          <div className="main-pane">
            <ContentPane />
          </div>
        </div>
      </Router>
    </div>
  );
}
