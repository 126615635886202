import React from 'react';

export default function InvalidCheck() {
  return (
    <div data-testid="errorredcross" className="error-banmark">
      <div className="ban-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div>
  );
}
