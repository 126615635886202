import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';

export default function Editor({ handleUpdateValue, value }) {
  const { t, i18n } = useTranslation('translation');
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  moment.locale(i18n.language);

  async function handleKeyEvent(key) {
    /* istanbul ignore else */
    if (key === 'Enter') {
      setIsLoading(true);
      await handleUpdateValue(newValue);
      setIsLoading(false);
      setIsEditingValue(false);
    } else if (key === 'Escape') {
      setIsEditingValue(false);
    }
  }

  async function handleOnClick() {
    setIsLoading(true);
    await handleUpdateValue(newValue);
    setIsLoading(false);
    setIsEditingValue(false);
  }

  return (
    <>
      {isEditingValue ? (
        <div className="editNameContainer">
          <input
            placeholder={t('editOrigin.editingName')}
            className="editNameInput"
            onChange={evt => setNewValue(evt.target.value)}
            onKeyDown={evt => handleKeyEvent(evt.key)}
          />
          <button
            className={isLoading ? 'loadingEditNameBtn' : 'editNameBtn'}
            onClick={() => handleOnClick()}
            data-testid={'editNameBtn'}
          >
            <i className="icon-check" />
          </button>
        </div>
      ) : (
        <div>
          <span className="fieldValue">{value}</span>
          <img
            src="/assets/icons/edit_2.svg"
            alt="edit"
            className="modifyNameIcon ml-2"
            data-testid={'editNameIcon'}
            onClick={() => setIsEditingValue(!isEditingValue)}
          />
        </div>
      )}
    </>
  );
}
