import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import NoAccess from './components/ViewComponents/Access/NoAccess';
import OriginListing from './components/ViewComponents/OriginListing/OriginListing';
import CreateOrigin from './components/ViewComponents/CreateOrigin/CreateOrigin';
import EditOrigin from './components/ViewComponents/EditOrigin/EditOrigin';
import OriginTypeListing from './components/ViewComponents/OriginTypeListing/OriginTypeListing';

export default function ContentPane() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.get('skipped_login')) {
    return <Redirect to="no-access" />;
  } else {
    return (
      <div className="ContentPane columns fullheight">
        <Switch>
          <Route exact path="/">
            <OriginListing />
          </Route>
          <Route path="/add-origin/:originType">
            <CreateOrigin />
          </Route>
          <Route path="/edit-origin/:originId">
            <EditOrigin />
          </Route>
          <Route path="/list-origins/:originType">
            <OriginTypeListing />
          </Route>
          <Route path="/no-access">
            <NoAccess />
          </Route>
        </Switch>
      </div>
    );
  }
}
