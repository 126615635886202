import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API';
import Loader from '../Loader/Loader';
import validationRules from '../../validationRules';
import { useUser } from '../../providers/UserProvider';
import { Fields } from '../Fields';

export default function SCImageField(props) {
  const { t } = useTranslation('translation');

  const [fields, setFields] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const didMountRef = useRef(false);
  const { csrf_token } = useUser();

  function save() {
    async function saveData() {
      setSaving(true);
      setSaveError(null);
      const payload = { service_credential: fields };
      try {
        await API.put(`/service_credentials/${props.originData.id}`, payload, {
          headers: { 'X-CSRF-Token': csrf_token },
          withCredentials: true,
        });
        setSaving(false);
        props.finishStep();
      } catch (error) {
        console.error(error.response);
        setSaveError(error.response.data.errors.message);
        setSaving(false);
      }
    }

    saveData();
  }

  useEffect(() => {
    if (didMountRef.current) {
      save();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatchSave]);

  const { config } = props.originData;

  useEffect(() => {
    setFields(
      props.fields?.reduce((result, field) => {
        result[field.key] = config[field.key] || '';
        return result;
      }, {})
    );

    props.fields.forEach(field => {
      field.state = true;
    });
  }, [props.fields, config]);

  const { setCanClick } = props;

  useEffect(() => {
    if (props.fields && fields) {
      setCanClick(
        Object.keys(props.fields).every(fieldKey => {
          if (
            props.fields[fieldKey].optional &&
            fields[props.fields[fieldKey].key] === ''
          ) {
            return true;
          } else if (
            props.fields[fieldKey].state &&
            fields[props.fields[fieldKey].key] !== ''
          ) {
            return true;
          }
          return false;
        })
      );
    }
  }, [fields, setCanClick, props.fields]);

  function updateFields(value, field, index) {
    if (field.validation_rules) {
      let fieldInfos = validationRules[field.validation_rules.type](
        value,
        field.validation_rules.params
      );

      if (fieldInfos.state) {
        field.state = true;
      } else {
        field.state = false;
        field.error = fieldInfos.err;
      }
    } else {
      field.state = true;
    }
    fields[field.key] = value;
    props.originData.config[field.key] = value;
    const updatedFields = {};
    Object.assign(updatedFields, fields);
    setFields(updatedFields);
  }

  function getCorrectInputClass({ state, key, optional }) {
    if (state) {
      return 'input';
    } else if (fields?.[key]) {
      return 'wronginput';
    } else if (optional) {
      return 'input';
    }
    return 'wronginput';
  }

  return (
    <div className="SCImageField SC">
      <div className="image-holder">
        <img src={props.source} alt="tutorial" />
      </div>
      <div className="validationBlock">
        <div className="multipleFields">
          {props.fields?.map((field, index) => (
            <Fields
              key={field.key}
              index={index}
              field={field}
              type={field.type}
              className={getCorrectInputClass(field)}
              onChange={value => updateFields(value, field, index)}
              value={fields?.[field.key] || ''}
              required={!field.optional}
            />
          ))}
        </div>
      </div>
      <div className="requiredFieldsLabel">
        {t('fieldsErrors.requiredFields')}
      </div>
      <span className="feedbackHolder">
        {saving ? (
          <Loader height="40" diameter={20} data-testid="saveLoading" />
        ) : null}
        <span className="saveError">{saveError}</span>
      </span>
    </div>
  );
}
