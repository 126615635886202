import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../Loader/Loader';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from '../../../utils';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

export default function OriginCard({ disable, ...props }) {
  const { t } = useTranslation('translation');
  const navigateTo = useHistory();

  function StateBlock(props) {
    let configuredClass = 'configuredOrigins';
    if (props.details?.configuredOrigins) {
      if (props.details.configuredOrigins > props.details.activeOrigins) {
        configuredClass += ' partiallyActive';
      } else {
        configuredClass += ' active';
      }
    }

    let activeClass = 'activeOrigins';
    if (props.details?.activeOrigins) {
      activeClass += ' active';
    }
    return (
      <div className="stateBlock">
        <div className="h-separator" />
        <ReactTooltip place="bottom" effect="solid" />
        {props.details ? (
          <div className="states" data-testid={`edit-${props.origin}`}>
            <div
              className={configuredClass}
              data-tip={t('originCard.configuredOrigins')}
            >
              <img src="/assets/icons/settings.svg" alt="configuredOrigins" />
              <span
                className="stateLabel"
                data-testid={`origin-card-${props.origin.toLowerCase()}-configured`}
              >
                {props.details.configuredOrigins}
              </span>
            </div>
            <div
              className={activeClass}
              data-tip={t('originCard.activeOrigins')}
            >
              <img src="/assets/icons/power-on-off.svg" alt="activeOrigins" />
              <span
                className="stateLabel"
                data-testid={`origin-card-${props.origin.toLowerCase()}-active`}
              >
                {props.details.activeOrigins}
              </span>
            </div>
          </div>
        ) : (
          <Loader height="30" diameter={15} />
        )}
      </div>
    );
  }

  function goToOrigins(evt) {
    if (!disable) {
      const path = `/list-origins/${props.origin}`;
      if (![...evt.target.classList].includes('originAdd')) {
        navigateTo.push(path);
      }
    }
  }

  return (
    <div
      data-testid={`origin-card-${props.origin.toLowerCase()}`}
      role="button"
      onClick={goToOrigins}
      className={classNames('originCard', { disable })}
    >
      {disable && <div className="comingSoon">{t('main.comingSoon')}</div>}
      {!disable && (
        <Link
          to={`/add-origin/${props.origin}`}
          className="originAdd"
          data-testid={`${props.origin}-add`}
        >
          +
        </Link>
      )}
      <div className="originIcon">
        <img
          src={`/assets/logos/origins/${props.origin}.png`}
          alt={`logo_${props.origin}`}
          width="150"
          height="80"
        />
      </div>
      <div className="originName">
        {capitalize(props.origin).replace(/_/g, ' ')}
      </div>

      {!disable && (
        <StateBlock
          origin={props.origin}
          details={props.details}
          data-testid={`${props.origin}-state`}
        />
      )}
    </div>
  );
}
