import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export const SboCheckbox = ({ value, onChange, label, id }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value || false}
            onChange={({ target: { checked } }) => onChange(checked)}
            data-testid={id}
          />
        }
        label={label}
      />
    </>
  );
};
