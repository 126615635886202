import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

function userLanguage() {
  if (navigator.languages) {
    return navigator.languages.find(lng => {
      return supported(lng);
    });
  }
}

function supported(lng) {
  if (lng) {
    if (
      ['en', 'fr'].find(val => {
        return lng.substr(0, val.length) === val;
      })
    ) {
      return lng;
    }
  }
}

const LanguageDetector = {
  type: 'languageDetector',
  detect: () => {
    return supported(navigator.language) || userLanguage() || 'en';
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng: "en-EN", // Force EN
    fallbackLng: 'en-EN',
    debug: false, // Activate to have details regarding i18n operations
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
