import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import {
  initializeAxiosMockAdapter,
  isMockEnabled,
} from './mocks/mock.config.js';

const PROJECT_BACK_URL = process.env.REACT_APP_PROJECT_BACK_URL;
const OMS_URL = process.env.REACT_APP_OMS_SERVER;

export const connector_server = axios.create({
  baseURL: PROJECT_BACK_URL,
});

export const oms_server = axios.create({
  baseURL: OMS_URL,
});

/* istanbul ignore if */
if (isMockEnabled()) {
  initializeAxiosMockAdapter(connector_server);
}

export default connector_server;

export function goToLogin() {
  const current_location = window.location.href;
  window.location.href =
    OMS_URL + 'users/sign_in?checklogin=true&goto=' + current_location;
}

export const refreshAuthLogic = failedRequest => {
  // Analytics cookie expired
  // Try to renew the cookie
  return oms_server
    .get('users/refresh_cookie', {
      withCredentials: true,
      skipAuthRefresh: true,
    })
    .then(response => {
      return Promise.resolve();
    })
    .catch(error => {
      // Session is dead, go to OMS login
      console.error(
        'Could not refresh auth_cookie. You need to sign-in again.'
      );
      goToLogin();
      return Promise.reject();
    });
};

createAuthRefreshInterceptor(connector_server, refreshAuthLogic, {
  statusCodes: [403, 401],
});
