import React, { createContext, useContext, useEffect, useState } from 'react';
import API from '../API';

const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let alive = true;
    API.get('users/me', { withCredentials: true }).then(({ data }) => {
      if (alive) {
        setUser(data);
        if (window.pendo) {
          window.pendo.initialize({
            visitor: {
              id: data.user_id,
            },
            account: {
              id: data.api_client_id,
            },
            excludeAllText: true,
          });
        }
      }
    });
    return () => {
      alive = false;
    };
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
