import { SnackProvider } from './SnackProvider';
import { UserProvider } from './UserProvider';
import { ThemeProvider } from '@mui/material';
import { theme } from '../config/theme.config';
import React from 'react';

export const Providers = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackProvider>
          <UserProvider>{children}</UserProvider>
        </SnackProvider>
      </ThemeProvider>
    </>
  );
};
