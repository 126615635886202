import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '../Switch/Switch';
import { SearchBar } from '../SearchBar/SearchBar';

export const FilterBar = ({ onChange }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    search: '',
    activeOnly: false,
  });

  useEffect(() => {
    onChange(filter);
  }, [filter, onChange]);

  const handleUpdate = (key, value) => {
    setFilter(prevState => ({ ...prevState, [key]: value }));
  };

  return (
    <>
      <div className={'filter-container'}>
        <div className={'search'}>
          <SearchBar
            className={'search-input'}
            label={t('main.searchOrigins')}
            onSearch={value => handleUpdate('search', value)}
          />
        </div>
        <div className={'active-only'}>
          <Switch
            testId={'active-only'}
            value={filter.activeOnly}
            label={t('main.activatedOrigins')}
            onChange={value => handleUpdate('activeOnly', !filter.activeOnly)}
          />
        </div>
      </div>
    </>
  );
};
