import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { camelize } from '../../../utils';

export const SboSelect = ({
  value,
  onChange,
  label,
  options,
  required,
  id,
}) => {
  return (
    <>
      <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
        <InputLabel id={`label-${camelize(label)}`}>{`${label} ${
          required && '*'
        }`}</InputLabel>
        <Select
          labelId={`label-${camelize(label)}`}
          value={value}
          label={`${label} ${required && '*'}`}
          onChange={({ target: { value } }) => onChange(value)}
          data-testid={id}
        >
          {options.map((opt, index) => (
            <MenuItem key={`${opt}-${index}`} value={opt}>
              {opt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
