import React from 'react';
import SCImage from './SCImage';
import SCImageField from './SCImageField';
import SCConnTest from './SCConnTest';
import SCActionButton from './SCActionButton';
import { useTranslation } from 'react-i18next';

export default function StepContent(props) {
  const { t } = useTranslation();
  const params = {
    ...props.step,
    finishStep: props.finishStep,
    setCanClick: props.setCanClick,
    setConnectionTestStatus: props.setConnectionTestStatus,
    dispatchSave: props.dispatchSave,
    originData: props.originData,
  };

  if (props.step?.type) {
    switch (props.step.type) {
      case 'image':
        return <SCImage {...params} />;
      case 'imageField':
        return <SCImageField {...params} />;
      case 'connTest':
        return <SCConnTest {...params} />;
      case 'actionButton':
        return <SCActionButton {...params} />;
      default:
        return <span>{t('stepContent.unexisting')}</span>;
    }
  } else {
    return <span>{t('stepContent.undefined')}</span>;
  }
}
