import { createTheme } from '@mui/material';

let theme = createTheme({});
theme = createTheme(theme, {
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          boxShadow: 'inset 0 0.0625em 0.125em rgb(0 0 0 / 5%)',
          backgroundColor: '#f4f4f4',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        // elevation8: {
        //   boxShadow: 'none',
        //   border: '1px solid #f4f4f4',
        // },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b5b5b5',
          },
        },
        notchedOutline: {
          borderColor: '#f4f4f4',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3273dc',
    },
  },
});

export { theme };
