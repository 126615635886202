import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils';
import API from '../../../API';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Editor from '../../Editor/Editor';
import { useSnack } from '../../../providers/SnackProvider';
import { useUser } from '../../../providers/UserProvider';
import 'moment/locale/fr';

export default function DetailedOriginCard(props) {
  const { t, i18n } = useTranslation('translation');

  const [newName, setNewName] = useState(props.origin.name);
  const { csrf_token } = useUser();
  const { createSnack } = useSnack();

  moment.locale(i18n.language);

  async function handleUpdateName(myName) {
    const payload = { name: myName };
    try {
      await API.put(`/service_credentials/${props.origin.id}`, payload, {
        headers: { 'X-CSRF-Token': csrf_token },
        withCredentials: true,
      });
      createSnack({
        severity: 'success',
        message: t('editOrigin.snackBarSucess', { newname: myName }),
        duration: 3000,
      });
      setNewName(myName);
    } catch (error) {
      console.error(error.response);
      createSnack({
        severity: 'error',
        message: t('editOrigin.snackBarError', { newname: myName }),
        duration: 3000,
      });
    }
  }

  return (
    <div className="detailedOriginCard">
      <div className="rowConfigLabel">
        <Editor
          value={capitalize(newName)}
          handleUpdateValue={handleUpdateName}
        />
      </div>
      <div className="row">
        <div className="col-infos">
          <div className="originInfos">
            <span className="fieldTitle">{t('main.originStatus')}</span>
            <span className="fieldValue">
              {props.origin.steps_completed ? (
                props.origin.order_fetcher_enabled ? (
                  <span className="label-active">
                    {t('main.originLabelActive')}
                  </span>
                ) : (
                  <span className="label-inactive">
                    {t('main.originLabelInactive')}
                  </span>
                )
              ) : (
                <span
                  className="label-configuration-in-progress"
                  data-testid={'origin-label'}
                >
                  {t('main.originLabelInProg')}
                </span>
              )}
            </span>
          </div>
          <div className="originInfos">
            <span className="fieldTitle">{t('main.originCreationDate')}</span>
            <span className="fieldValue">
              {moment(props.origin.created_at).format('L LTS').split(' ')[0]}
            </span>
          </div>
          <div className="originInfos">
            <span className="fieldTitle">{t('main.originUpdateDate')}</span>
            <span className="fieldValue">
              {moment(props.origin.updated_at).format('L LTS').split(' ')[0]}
            </span>
          </div>
        </div>
        <div className="col-utils">
          <Link to={`/edit-origin/${props.origin.id}`} className="configureBtn">
            <img src="/assets/icons/settings.svg" alt="configure" />
            <span className="configureLabel">{t('main.configuration')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
