import React from 'react';

export const Switch = ({ onChange, value, label, className, testId }) => {
  return (
    <>
      <label
        className={`toggle-control ${className}`}
        data-testid={`switch-${testId}`}
      >
        <input
          type="checkbox"
          checked={value}
          onChange={evt => onChange(evt.target.checked, evt)}
        />
        <span className="control"></span>
        <span className={'toggle-label'}>{label}</span>
      </label>
    </>
  );
};
