import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { Alert } from '@mui/material';

const SnackContext = createContext(null);

export const SnackProvider = ({ children }) => {
  const [snackOpt, setSnackOpt] = useState({
    severity: 'error',
    message: '',
    position: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    duration: 1000,
    open: false,
  });

  const createSnack = opt => {
    setSnackOpt({ ...snackOpt, ...opt, open: true });
  };

  const closeSnack = () => {
    setSnackOpt({ ...snackOpt, open: false });
  };

  return (
    <>
      <SnackContext.Provider value={{ createSnack, closeSnack }}>
        {children}
        <Snackbar
          open={snackOpt.open}
          autoHideDuration={snackOpt.duration}
          onClose={closeSnack}
          anchorOrigin={snackOpt.position}
          TransitionComponent={Slide}
        >
          <Alert
            severity={snackOpt.severity}
            sx={{ width: '100%' }}
            data-testid={'editingNameAlert'}
          >
            {snackOpt.message}
          </Alert>
        </Snackbar>
      </SnackContext.Provider>
    </>
  );
};

export const useSnack = () => {
  return useContext(SnackContext);
};
