/* istanbul ignore file */

import MockAdapter from 'axios-mock-adapter';

export const isMockEnabled = () => {
  return process.env.REACT_APP_MOCK_ENABLED === 'true';
};

const mocktimer = process.env.REACT_APP_MOCK_TIMER || 2000;

export const initializeAxiosMockAdapter = instance => {
  const mock = new MockAdapter(instance, {
    onNoMatch: 'throwException',
    delayResponse: mocktimer,
  });
  mock.onGet('users/me').reply(() => [
    200,
    {
      csrf_token: 'my token super secure',
      api_client_id: 1234,
      user_id: 4321,
      client_name: 'My awesome company',
    },
  ]);
  mock
    .onGet(/\/service_credentials\/\w+\/check_credentials/)
    .replyOnce(() => [200, { passedCheck: false }])
    .onGet(/\/service_credentials\/\w+\/check_credentials/)
    .reply(() => [200, { passedCheck: true }]);

  mock
    .onGet(/\/service_credentials\/\w+$/)
    .reply(config => getServiceCredential(config))
    .onPut(/\/service_credentials\/\w+$/)
    .reply(config => putNewServiceCredentialsName(config));

  mock
    .onGet(/\/service_credentials/)
    .reply(config => getServiceCredentials(config));

  mock
    .onPut(/\/service_credentials\/\w+/)
    .replyOnce(() => [500, { errors: { message: 'server exploded' } }])
    .onPut(/\/service_credentials\/\w+/)
    .reply(config => getServiceCredential(config));

  mock.onPost(/\/service_credentials\/\w+\/finish_step/).reply(config => [
    200,
    {
      service_credential: {
        id: '43',
        name: 'PrestaTest',
        type: 'ServiceCredential::Prestashop',
        config: {
          apiKey: '10000000000000',
          host: 'https://maboutique.fr/api',
          url: 'https://admin.maboutique.fr',
        },
        order_fetcher_enabled: false,
        order_fetcher_id: 123,
        service_creation_steps: [
          {
            name: 'Prestashop web services activation',
            finished: true,
            type: 'image',
            source: '/assets/mockSteps/prestashop_step_0_en.png',
          },
          {
            name: 'API key collection',
            finished: false,
            type: 'imageField',
            source: '/assets/mockSteps/prestashop_step_1_en.png',
            fields: [
              {
                key: 'apiKey',
                name: 'API Key',
                validation_rules: {
                  type: 'apiKey',
                  params: {
                    nb_chars: 14,
                  },
                },
                state: true,
              },
            ],
          },
          {
            name: 'Store link',
            finished: true,
            type: 'imageField',
            source: '/assets/mockSteps/prestashop_step_2_en.png',
            fields: [
              {
                key: 'host',
                name: 'Host (https://www.yourwebsite.com/api)',
                validation_rules: {
                  type: 'url',
                  params: {
                    urlStartingStrings: ['http://', 'https://'],
                  },
                },
              },
              {
                key: 'url',
                name: 'Admin connexion URL of your Prestashop',
                validation_rules: {
                  type: 'url',
                  params: {
                    urlStartingStrings: ['http://', 'https://'],
                  },
                },
                optional: true,
              },
              {
                key: 'select-field',
                name: 'my field',
                type: 'select',
                options: ['FR', 'UK', 'DE'],
              },
              {
                key: 'checkbox-field',
                name: 'my checkbox',
                type: 'checkbox',
              },
            ],
          },
          {
            name: 'OAuth button tester',
            finished: false,
            type: 'actionButton',
            action_button: {
              url: 'https://prestashop.com',
              name: 'Login with Prestashop',
            },
          },
          {
            name: 'Connectivity test',
            finished: false,
            service_credential_data: {
              installed_from: 'prestashop',
            },
            type: 'connTest',
            needed_steps: [0, 1, 2],
            blocked: true,
          },
        ],
      },
    },
  ]);

  mock
    .onPost(/\/service_credentials/)
    .reply(config => createServiceCredentialResponse(config));

  mock.onPut(/\/order_fetchers\/\w+/).reply(config => putOrderFetcher(config));
};

const createServiceCredentialResponse = config => {
  return [200, { service_credential: { id: 43 } }];
};

const getServiceCredential = config => {
  const id = extractIdPathParamFromUrl(config) || 43;
  return [
    200,
    {
      service_credential: {
        id: id,
        name: 'PrestaTest',
        type: 'ServiceCredential::Prestashop',
        config: {
          host: 'https://maboutique.fr/api',
          url: 'https://admin.maboutique.fr',
        },
        order_fetcher_enabled: false,
        order_fetcher_id: 123,
        service_creation_steps: [
          {
            name: 'Prestashop web services activation',
            finished: false,
            type: 'image',
            source: '/assets/mockSteps/prestashop_step_0_en.png',
          },
          {
            name: 'API key collection',
            finished: false,
            type: 'imageField',
            source: '/assets/mockSteps/prestashop_step_1_en.png',
            fields: [
              {
                key: 'apiKey',
                name: 'API Key',
                validation_rules: {
                  type: 'apiKey',
                  params: { nb_chars: 14 },
                },
              },
            ],
          },

          {
            name: 'Store link',
            finished: true,
            type: 'imageField',
            source: '/assets/mockSteps/prestashop_step_2_en.png',
            fields: [
              {
                key: 'host',
                name: 'Host (https://www.yourwebsite.com/api)',
                validation_rules: {
                  type: 'url',
                  params: { urlStartingStrings: ['http://', 'https://'] },
                },
              },
              {
                key: 'url',
                name: 'Admin connexion URL of your Prestashop',
                validation_rules: {
                  type: 'url',
                  params: { urlStartingStrings: ['http://', 'https://'] },
                },
                optional: true,
              },
              {
                name: 'my field',
                key: 'field',
                type: 'select',
                options: ['FR', 'UK', 'DE'],
              },
            ],
          },
          {
            name: 'OAuth button tester',
            finished: false,
            type: 'actionButton',
            action_button: {
              url: 'https://prestashop.com',
              name: 'Login with Prestashop',
            },
          },
          {
            name: 'Connectivity test',
            finished: false,
            service_credential_data: {
              installed_from: 'prestashop',
            },
            type: 'connTest',
            needed_steps: [0, 1, 2],
          },
        ],
      },
    },
  ];
};

const getServiceCredentials = config => {
  return [
    200,
    {
      service_credentials: [
        {
          id: 1,
          name: 'prestashop_one',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 123,
          order_fetcher_enabled: false,
          steps_completed: false,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 2,
          name: 'prestashop_two',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 234,
          order_fetcher_enabled: true,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 3,
          name: 'prestashop_tree',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 284,
          order_fetcher_enabled: true,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 4,
          name: 'prestashop_four',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 84,
          order_fetcher_enabled: false,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 5,
          name: 'amazon first shop',
          type: 'ServiceCredential::Amazon',
          order_fetcher_id: 345,
          order_fetcher_enabled: false,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 6,
          name: 'woowoo',
          type: 'ServiceCredential::Woocommerce',
          order_fetcher_id: 456,
          order_fetcher_enabled: false,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 7,
          name: 'shopishopi',
          type: 'ServiceCredential::Shopify',
          order_fetcher_id: 567,
          order_fetcher_enabled: true,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 8,
          name: 'natdec',
          type: 'ServiceCredential::NatureEtDecouvertes',
          order_fetcher_id: 678,
          order_fetcher_enabled: true,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 9,
          name: 'prestashop_5',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 6798,
          order_fetcher_enabled: true,
          steps_completed: true,
          created_at: '2022-04-13T08:05:17.194Z',
          updated_at: '2022-04-13T08:05:17.194Z',
        },
        {
          id: 9,
          name: 'prestashop_five',
          type: 'ServiceCredential::Prestashop',
          order_fetcher_id: 85,
          order_fetcher_enabled: true,
        },
      ],
    },
  ];
};

const putOrderFetcher = config => {
  console.debug(config);
  console.debug('putting order fetcher to : ', JSON.parse(config.data));
  return [200, null];
};

const putNewServiceCredentialsName = config => {
  return [200, { name: config.data.name }];
};

const extractIdPathParamFromUrl = config => {
  return config.url.split('/').pop();
};
