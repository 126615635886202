import React from 'react';

export const Input = ({
  state,
  value,
  label,
  id,
  error,
  index,
  className,
  onChange,
  required,
}) => {
  return (
    <>
      <div className="floatingField">
        <input
          className={`${className}`}
          id={id}
          placeholder={`${label} ${required ? '*' : ''}`}
          value={value || ''}
          onChange={({ target: { value } }) => onChange(value)}
        />
        <label
          className={state ? null : 'wronglabel'}
          htmlFor={id}
          data-testid={state ? 'correctlabel' : `wronglabel${index}`}
        >
          {`${label} ${required ? '*' : ''}`} {state ? null : error}
        </label>
      </div>
    </>
  );
};
